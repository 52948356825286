/*------------------------------------*\
    Page Template: Lavora con noi
    lavora-con-noi.php
    .page-template-lavora-con-noi
\*------------------------------------*/

.page-template-lavora-con-noi {

    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .lavora_con_noi__header {
        display: flex;
        position: relative;
        flex-direction: column;
        min-height: 100vh;

        @media #{$media-sx} {
            padding: 0;
        }

        figure {
            position: absolute;
            pointer-events: none;
            z-index: -1;
            opacity: 0.2;

            @media #{$media-sx} {
                width: 300vw;
            }

            &.scritta {
                bottom: 46%;

                @media #{$media-sx} {
                    bottom: auto;
                }
            }
        }

        .col-1 {
            width: 100%;
            padding: $gutter * 5 $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 3 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter * 3 $gutter / 2 $gutter / 2 $gutter / 2;
            }

            h1 {
                font-size: 1.8rem;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h3 {
                font-size: 6.5rem;
                font-weight: $f-weight-500;
                margin-top: $gutter / 2;

                @media #{$media-m} {
                    font-size: 5.6rem;
                    line-height: 1.3;
                }

                @media #{$media-sm} {
                    font-size: 5rem;
                }

                @media #{$media-sx} {
                    font-size: 4rem;
                }
            }

            p {
                font-size: 3rem;
                font-weight: $f-weight-300;
                line-height: 1.4;
                max-width: 80%;
                margin-top: $gutter * 2;

                @media #{$media-bx} {
                    max-width: 100%;
                }

                @media #{$media-m} {
                    font-size: 2.6rem;
                    margin-top: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2rem;
                    margin-top: $gutter / 2;
                }
            }
        }

        //.col-1


    }
    // .lavora_con_noi__header

    .lavora_con_noi__posizioni_aperte {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: $gutter $gutter * 5 0 $gutter * 5;

        @media #{$media-bx} {
            padding: $gutter $gutter * 2 0 $gutter * 3;
        }

        @media #{$media-m} {
            padding: $gutter $gutter * 2 0 $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter $gutter * 2 0 $gutter * 2;
        }

        @media #{$media-s} {
            padding: $gutter;
            margin-top: $gutter;
            background-color: var(--c-accent);
            color: var(--c-white);
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
            margin-bottom: $gutter / 2;
        }

        h1 {
            font-size: 4rem;

            @media #{$media-sx} {
                font-size: 3rem;
            }
        }

        .posizione {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 2.2rem;
            font-weight: $f-weight-300;

            @media #{$media-s} {
                border-bottom: 1px solid var(--c-white);
                justify-content: space-between;
            }

            @media #{$media-sx} {
                font-size: 1.6rem;
                font-weight: $f-weight-400;
                margin: $gutter / 4 $gutter / 4 $gutter / 2 $gutter / 4;
            }

            span {
                background-color: var(--c-accent);
                border-radius: 50%;
                width: 8px;
                height: 8px;

                @media #{$media-s} {
                    display: none;
                }
            }

            a {
                font-size: 1.8rem;
                text-decoration: underline;
                display: flex;
                align-items: center;
                gap: 10px;
                margin-left: $gutter;

                h6 {
                    @media #{$media-s} {
                        display: none;
                    }
                }

                svg {
                    @media #{$media-s} {
                        fill: var(--c-white);
                    }
                }
            }
        }

    }

    .lavora_con_noi__form {
        display: flex;

        @media #{$media-sx} {
            padding: 0 $gutter / 2;
        }

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 0 $gutter * 5 0 $gutter * 5;
            padding: $gutter $gutter $gutter * 2 $gutter;

            @media #{$media-bx} {
                margin: 0 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                margin: 0 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                margin: 0 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                margin: 0;
                padding: 0;
                text-align: left;
            }

            .form_gravity {
                display: flex;
                justify-content: center;
                margin-top: $gutter / 2;

                .gform_wrapper {
                    width: 60%;

                    @media #{$media-bx} {
                        width: 80%;
                    }

                    @media #{$media-m} {
                        width: 100%;
                    }
                }

                .titoloForm {
                    font-size: 2.8rem;
                    text-align: center;
                }

                .altreInfo {
                    .gfield_label {
                        font-size: 2.8rem !important;
                        font-weight: $f-weight-500 !important;
                    }
                }

                input, textarea, select {
                    background: transparent;
                }
            }

            .gform_confirmation_message {
                width: 60%;
                margin: $gutter;
                font-weight: $f-weight-300;
                font-size: 1.8rem;

                @media #{$media-b} {
                    width: 80%;
                }

                @media #{$media-m} {
                    width: auto;
                }

                @media #{$media-sm} {
                    margin: $gutter 0;
                    text-align: left;
                }
            }
        }
    }
    // .lavora_con_noi__form
}

// .page-template-lavora-con-noi