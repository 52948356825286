/*------------------------------------*\
    Page Template: Servizio
    servizio.php
    .page-template-servizio
\*------------------------------------*/

.page-template-servizio {

    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .servizi__header {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .scrittaBg {
            position: absolute;
            pointer-events: none;
            left: 0;
            transform: scale(2);
            z-index: -1;
            opacity: 0.1;
            transform-origin: top left;

            @media #{$media-sm} {
                opacity: 0.3;
                transform-origin: center;
            }
        }

        .col-1 {
            width: 100%;
            padding: $gutter * 5 $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 3 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter * 3 $gutter / 2 0 $gutter / 2;
            }

            h1 {
                font-size: 1.8rem;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h3 {
                font-size: 6.5rem;
                font-weight: $f-weight-500;
                margin-top: $gutter / 2;

                @media #{$media-m} {
                    font-size: 5.6rem;
                    line-height: 1.3;
                }

                @media #{$media-sm} {
                    font-size: 5rem;
                }

                @media #{$media-sx} {
                    font-size: 4rem;
                }
            }

            p {
                font-size: 3rem;
                font-weight: $f-weight-300;
                line-height: 1.4;
                max-width: 80%;
                margin-top: $gutter * 2;

                @media #{$media-bx} {
                    max-width: 100%;
                }

                @media #{$media-m} {
                    font-size: 2.6rem;
                    margin-top: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2rem;
                    margin-top: $gutter / 2;
                }
            }

            .link {
                display: flex;
                align-items: center;
                margin-top: $gutter / 2;

                a {
                    display: inline-flex;
                    align-items: center;
                    margin-top: $gutter / 2;

                    .text {
                        font-size: 1.8rem;
                        font-weight: $f-weight-300;
                        margin-left: $gutter / 3;
                        text-transform: uppercase;
                        transition: $trans-default;
                        line-height: 1.4;

                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }

                    svg {
                        transition: $trans-default;
                    }

                    &:hover {
                        color: var(--c-accent);

                        .text {
                            transform: translateX(10px);
                        }

                        svg {
                            transform: scale(1.1) translateX(10px);
                            g, path {
                                stroke: var(--c-accent);
                                stroke-width: 1px;
                            }
                        }
                    }
                }
            }
        }

        //.col-1

        .col-2 {
            display: flex;
            margin-top: $gutter * 1.5;

            @media #{$media-sm} {
                flex-direction: column;
                align-items: center;
            }

            @media #{$media-sx} {
                margin-top: $gutter;
            }

            .box_immagine {
                width: 45rem;
                height: 45rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--c-accent);
                flex: 0 0 auto;

                @media #{$media-m} {
                    width: 35rem;
                    height: 35rem;

                    figure {
                        max-width: 80%;
                    }
                }

                @media #{$media-sm} {
                    margin-bottom: $gutter;
                    width: 100%;
                    height: 40rem;

                    figure {
                        max-width: 100%;
                    }
                }

                @media #{$media-sx} {
                    height: 30rem;
                    padding: $gutter / 2 0;

                    figure {
                        display: flex;
                        height: 100%;

                        img {
                            height: auto;
                        }
                    }
                }

                figure {
                    display: flex;
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 $gutter * 5;

                @media #{$media-bx} {
                    padding: 0 $gutter * 3;
                }

                @media #{$media-b} {
                    padding: 0 $gutter * 2;
                }

                @media #{$media-s} {
                    padding: 0 $gutter;
                }

                p {
                    font-size: 2.2rem;
                    font-weight: $f-weight-300;
                    margin-bottom: $gutter / 2;

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                        text-align: center;
                    }
                }
            }
        }

    }
    // .servizi__header

    .servizi__section1 {
        display: flex;
        flex-direction: column;

        article {
            padding: $gutter * 2.5 $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter * 2.5 $gutter * 3 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter * 2.5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 2 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 2 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter $gutter / 2 0 $gutter / 2;
            }

            h2 {
                font-size: 1.8rem;
                font-weight: $f-weight-500;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h3 {
                font-size: 6rem;

                @media #{$media-m} {
                    font-size: 5.6rem;
                    line-height: 1.3;
                }

                @media #{$media-sm} {
                    font-size: 5rem;
                }

                @media #{$media-sx} {
                    font-size: 3.6rem;
                    line-height: 1.1;
                    margin: $gutter / 2 0;
                }
            }

            p {
                font-size: 2.2rem;
                font-weight: $f-weight-300;
                max-width: 80%;
                margin-bottom: $gutter / 2;

                @media #{$media-m} {
                    font-size: 2rem;
                    max-width: 100%;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            .link {
                display: flex;
                align-items: center;
                margin-top: $gutter / 2;

                a {
                    display: inline-flex;
                    align-items: center;
                    margin-top: $gutter / 2;

                    .text {
                        font-size: 1.8rem;
                        font-weight: $f-weight-300;
                        margin-left: $gutter / 3;
                        text-transform: uppercase;
                        transition: $trans-default;
                        line-height: 1.4;

                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }

                    svg {
                        transition: $trans-default;
                    }

                    &:hover {
                        color: var(--c-accent);

                        .text {
                            transform: translateX(10px);
                        }

                        svg {
                            transform: scale(1.1) translateX(10px);
                            g, path {
                                stroke: var(--c-accent);
                                stroke-width: 1px;
                            }
                        }
                    }
                }
            }

            .voci_elenco {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                margin: $gutter 0 $gutter * 1.5;
                text-align: left;

                @media #{$media-m} {
                    margin: $gutter 0 0;
                }

                @media #{$media-sm} {
                    gap: 20px;
                }

                @media #{$media-sx} {
                    margin-bottom: $gutter;
                }

                h4 {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--c-white);
                    font-size: 2.2rem;
                    font-weight: $f-weight-300;
                    padding: $gutter / 3;

                    @media #{$media-m} {
                       width: 47%; 
                    }

                    @media #{$media-sm} {
                        width: 100%;
                        font-size: 2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }
            }
        }

        .richiedi_info {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: $gutter / 4;
        }

        .other_links {
            margin-top: $gutter * 2;
            padding: 0 $gutter * 5;

            @media #{$media-bx} {
                padding: 0 0 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: 0 0 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 3 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter * 3 $gutter / 2 0 $gutter / 2;
            }

            h4 {
                font-size: 3rem;
            }

            .content {
                display: flex;
                flex-wrap: wrap;
                gap: 80px;
                margin-top: $gutter / 2;

                @media #{$media-b} {
                    gap: 20px;
                }
                
                @media #{$media-m} {
                    gap: 10px;
                }

                .item {
                    flex: 1 1 auto;
                }

                a {
                    display: flex;
                    align-items: center;
                    font-size: 2.4rem;
                    text-decoration: underline;
                    transition: $trans-default;
                    text-align: left;

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }

                    &:hover {
                        color: var(--c-accent);
                    }

                    figure {
                        height: 60px;
                        margin-right: $gutter / 2;
                        flex: 0 0 auto;

                        @media #{$media-m} {
                            width: 60px;
                            justify-content: center;
                        }

                        @media #{$media-sx} {
                            width: 50px;
                            height: 50px;
                        }

                        img {
                            width: auto;
                            height: auto;
                        }
                    }
                }
            }
        }

    }
    // .servizi__section1
}

// .page-template-servizio