/*------------------------------------*\
    Page Template: Homepage
    homepage.php
    .page-template-homepage
\*------------------------------------*/

.page-template-homepage {

    .home__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100vh;
        position: relative;
        overflow: hidden;

        @media #{$media-sm} {
            padding-top: 20vh;
            flex-direction: column;
        }

        @media #{$media-sx} {
            padding-top: 15vh;
        }

        &#headline {
            width: 100%;
            background: url('../images/headline.svg') repeat-x center left,
                url('../images/mask.svg') no-repeat top left,
                url('../images/headline_color.svg') repeat-x center left;
            background-size: 160vw auto,
                cover,
                160vw auto;
            background-attachment: fixed, scroll, fixed !important;
            animation: bg_anim 60s infinite linear;
            min-height: 1000px;

            @media #{$media-sm} {
                background-size: 320vw auto,
                    cover,
                    320vw auto;
                min-height: auto;
            }

            @media #{$media-sx} {
                max-height: 700px;
            }
        }

        @keyframes bg_anim {
            0% {
                background-position-x: 0, 0, 0;
            }

            100% {
                background-position-x: -160vw, 0, -160vw;
            }
        }

        // #headline (animazione scritta header)

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            @media #{$media-b} {
                padding: 0 $gutter;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            h1 {
                font-size: 1.8rem;
                font-weight: $f-weight-500;
                margin-bottom: $gutter;

                @media #{$media-sm} {
                    font-size: 1.6rem;
                    margin-bottom: $gutter / 2;
                }
            }

            h3 {
                font-size: 4rem;
                font-weight: $f-weight-300;
                line-height: 1.2;

                @media #{$media-sm} {
                    font-size: 2.2rem;
                    font-weight: $f-weight-400;
                }

                @media #{$media-sx} {
                    font-size: 2rem;
                }
            }

            p {
                font-weight: $f-weight-300;
                font-size: 8.5rem;
                line-height: 1;
                margin-bottom: $gutter * 4;

                @media #{$media-sm} {
                    font-size: 6.5rem;
                    margin-bottom: $gutter;
                    font-weight: $f-weight-400;
                }

                @media #{$media-sx} {
                    font-size: 5rem;
                }

                span {
                    color: var(--c-accent);
                    font-weight: $f-weight-500;
                }
            }

            img {
                position: absolute;
                bottom: $gutter;
                left: $gutter;
                animation: arrow_accent_ani 0.5s infinite ease-in-out alternate-reverse;

                @media #{$media-sx} {
                    left: 44%;
                }
            }

            @keyframes arrow_accent_ani {
                0% {
                    transform: translateY(20px);
                }

                100% {
                    transform: translateY(0);
                }
            }
        }

        //.col-1

    }

    // .home__header

    #home_fascia_arancione {
        width: 100%;
        background: #F3811D url('../images/bg_about.svg') no-repeat center left 60px / 36%;
        min-height: 70vh;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media #{$media-sm} {
            align-items: flex-start;
            background-position: bottom;
            min-height: 70rem;
            background-size: 60%;
        }

        @media #{$media-s} {
            background-size: 70%;
        }

        @media #{$media-sx} {
            background-size: 100%;
            min-height: 68rem;
        }

        .col-1 {
            width: 50%;
            margin-left: 40%;
            max-width: 1000px;
            padding: $gutter * 2 $gutter;
            text-align: center;
            color: var(--c-white);
            font-size: 3rem;

            @media #{$media-m} {
                width: 60%;
                font-size: 2.6rem;
            }

            @media #{$media-sm} {
                width: 100%;
                margin-left: 0;
                padding: $gutter;
            }

            @media #{$media-s} {
                font-size: 2.2rem;
            }

            @media #{$media-sx} {
                font-size: 2rem;
                padding: $gutter $gutter / 2;
            }
        }
    }

    //#home__fascia_arancione

    .home__servizi {
        display: flex;
        margin-top: $gutter * 4;

        @media #{$media-sm} {
            margin-top: $gutter * 2.5;
        }

        @media #{$media-sx} {
            margin-top: $gutter;
        }

        .col-1 {
            width: 100%;

            .box_servizio {
                display: flex;
                margin-bottom: $gutter * 6;

                @media #{$media-bx} {
                    margin-bottom: $gutter * 4;
                }

                @media #{$media-sm} {
                    flex-direction: column;
                    margin-bottom: $gutter * 2;
                }

                @media #{$media-s} {
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    margin-bottom: $gutter / 2;
                }

                .col-1 {
                    width: 20%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media #{$media-sm} {
                        width: 100%;
                    }

                    @media #{$media-sx} {
                        figure {
                            max-width: 30%;
                            max-height: $gutter * 2;

                            img {
                                height: auto;
                            }
                        }
                    }
                }

                .col-2 {
                    width: 80%;

                    @media #{$media-sm} {
                        width: 100%;
                    }

                    @media #{$media-sm} {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: $gutter / 2 $gutter;
                    }

                    @media #{$media-sx} {
                        padding: $gutter / 2;
                    }

                    h1 {
                        font-size: 1.8rem;
                        font-weight: $f-weight-500;

                        @media #{$media-sm} {
                            text-align: center;
                        }

                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }

                    p {
                        font-size: 6rem;

                        @media #{$media-b} {
                            line-height: 1.2;
                            padding: $gutter / 2 0;
                        }

                        @media #{$media-sm} {
                            font-size: 5rem;
                            text-align: center;
                            line-height: 1.3;
                        }

                        @media #{$media-sx} {
                            font-size: 3.6rem;
                            line-height: 1.2;
                            padding: $gutter / 3 0;
                        }
                    }

                    h2 {
                        font-size: 2.2rem;
                        font-weight: $f-weight-300;
                        max-width: 1000px;

                        @media #{$media-sm} {
                            text-align: center;
                            font-size: 2rem;
                        }

                        @media #{$media-sx} {
                            font-size: 1.8rem;
                        }
                    }

                    a {
                        display: inline-flex;
                        align-items: center;
                        margin-top: $gutter / 2;

                        .text {
                            font-size: 1.8rem;
                            font-weight: $f-weight-300;
                            margin-left: $gutter / 3;
                            text-transform: uppercase;
                            transition: $trans-default;

                            @media #{$media-sx} {
                                font-size: 1.6rem;
                            }
                        }

                        svg {
                            transition: $trans-default;
                        }

                        &:hover {
                            color: var(--c-accent);

                            .text {
                                transform: translateX(10px);
                            }

                            svg {
                                transform: scale(1.1) translateX(10px);

                                g,
                                path {
                                    stroke: var(--c-accent);
                                    stroke-width: 1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //.home__partners

    .home__partners {
        display: flex;
        margin-top: $gutter * 2;
        justify-content: center;

        @media #{$media-bx} {
            margin-top: $gutter;
        }

        @media #{$media-s} {
            overflow-x: auto;
        }

        .box_partners {
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 40px;

            @media #{$media-bx} {
                width: 90%;
            }

            @media #{$media-sx} {
                width: 100%;
            }

            .gallery_brands {
                width: 100%;

                .single_partner {
                    width: 160px;
                    height: 160px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: var(--c-white);
                    padding: 5px;
                    margin: 0 $gutter / 2;

                    @media #{$media-sx} {
                       width: 140px;
                       height: 140px;
                    }
                }
            }


        }
    }

    //.home__partners

    .home__news {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $gutter * 3;

        @media #{$media-m} {
            overflow-x: auto;
        }

        @media #{$media-s} {
            margin-top: $gutter;
        }

        @media #{$media-sx} {
            margin-top: $gutter * 2;
        }

        .box_news {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            gap: 40px;

            @media #{$media-m} {
                flex-wrap: nowrap;
                width: fit-content;
                padding: 0 $gutter;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            .single_news {
                width: 30%;
                max-width: 480px;
                border: 2px solid var(--c-grey-normal);

                @media #{$media-m} {
                    width: 70vw;
                }

                .top {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: $gutter * 1.5;
                    border-bottom: 2px solid var(--c-grey-normal);
                    position: relative;
                    font-size: 1.8rem;
                    font-weight: $f-weight-200;

                    img {
                        position: absolute;
                        right: $gutter / 3;
                        width: 35px;
                        height: auto;
                    }
                }

                .title {
                    display: flex;
                    justify-content: center;
                    padding: $gutter / 2 $gutter / 2;
                    font-size: 2.4rem;
                    line-height: 1.3;
                    text-align: center;
                    min-height: $gutter * 2.5;
                    overflow: hidden;
                    border-bottom: 2px solid var(--c-grey-normal);

                    @media #{$media-m} {
                        min-height: $gutter * 3;
                    }

                    @media #{$media-sx} {
                        font-size: 2rem;
                    }
                }

                .content {
                    padding: $gutter / 2;

                    p {
                        font-size: 1.6rem;
                        font-weight: $f-weight-300;
                        min-height: $gutter * 3;
                    }

                    .link {
                        display: flex;
                        justify-content: center;

                        a {
                            display: inline-flex;
                            align-items: center;
                            margin-top: $gutter / 2;

                            .text {
                                font-size: 1.8rem;
                                font-weight: $f-weight-300;
                                margin-left: $gutter / 3;
                                text-transform: uppercase;
                                transition: $trans-default;
                            }

                            svg {
                                transition: $trans-default;
                            }

                            &:hover {
                                color: var(--c-accent);

                                .text {
                                    transform: translateX(10px);
                                }

                                svg {
                                    transform: scale(1.1) translateX(10px);

                                    g,
                                    path {
                                        stroke: var(--c-accent);
                                        stroke-width: 1px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .vai_news {
        margin-top: $gutter;
        display: flex;
        justify-content: center;

        a {
            @media #{$media-sx} {
                font-size: 1.4rem;
            }
        }
    }

    //.home__news
}

// .page-template-homepage