/*------------------------------------*\
    Page Template: Contatti
    contatti.php
    .page-template-contatti
\*------------------------------------*/

.page-template-contatti {

    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .contatti__header {
        display: flex;
        position: relative;
        flex-direction: column;
        min-height: 100vh;

        @media #{$media-sx} {
            padding: 0 $gutter / 2;
        }

        figure {
            position: absolute;
            pointer-events: none;
            z-index: -1;

            @media #{$media-sx} {
                width: 300vw;
            }

            &.scritta1 {
                right: -48rem;
                top: 18rem;
            }

            &.scritta2 {
                left: -50rem;
                bottom: 0;

                @media #{$media-sx} {
                    bottom: auto;
                }
            }
        }

        .col-1 {
            width: 100%;
            padding: $gutter * 5 $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 3 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter * 3 0 0;
            }

            h1 {
                font-size: 1.8rem;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h3 {
                font-size: 6.5rem;
                font-weight: $f-weight-500;
                margin-top: $gutter / 2;

                @media #{$media-m} {
                    font-size: 5.6rem;
                    line-height: 1.3;
                }

                @media #{$media-sm} {
                    font-size: 5rem;
                }

                @media #{$media-sx} {
                    font-size: 4rem;
                }
            }

            p {
                font-size: 3rem;
                font-weight: $f-weight-300;
                line-height: 1.4;
                max-width: 80%;
                margin-top: $gutter * 2;

                @media #{$media-bx} {
                    max-width: 100%;
                }

                @media #{$media-m} {
                    font-size: 2.6rem;
                    margin-top: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2rem;
                    margin-top: $gutter / 2;
                }
            }
        }

        //.col-1

        .content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 40px;
            padding: $gutter $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter / 2 0;
            }

            .contatti_recapiti {
                display: inline-flex;
                flex-direction: column;
                padding: $gutter / 2 $gutter $gutter / 2 $gutter / 2;
                background-color: var(--c-white);
                width: max-content;
                align-self: flex-start;

                @media #{$media-b} {
                    width: 100%;
                }

                h4 {
                    font-size: 2.2rem;
                    font-weight: $f-weight-500;
                    margin-bottom: $gutter / 3;

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }

                .indirizzo {
                    font-size: 2.2rem;
                    font-weight: $f-weight-300;
                    margin-bottom: $gutter / 2;

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }

                    a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        line-height: 1;

                        svg {
                            margin-left: $gutter / 2;
                            margin-top: - $gutter / 4;
                        }
                    }
                }

                h6 {
                    font-size: 2.2rem;
                    font-weight: $f-weight-700;

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }

                    a {
                        font-weight: $f-weight-300;
                    }
                }
            }

            // .contatti_recapiti

            .contatti_gmaps {
                flex: 1 0 auto;
                display: flex;
                flex-direction: column;
                padding: $gutter $gutter $gutter * 2 $gutter;
                background-color: var(--c-grey-lighter);

                @media #{$media-sx} {
                    padding: $gutter / 2;
                }

                h4 {
                    font-size: 4rem;

                    @media #{$media-sx} {
                        font-size: 3.6rem;
                    }
                }

                .acf-map {
                    height: 565px;

                    @media #{$media-sm} {
                        height: 400px;
                    }
                }
            }
        }

        // .content



    }

    // .contatti__header

    .contatti__form {
        display: flex;

        @media #{$media-sx} {
            padding: 0 $gutter / 2;
        }

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: $gutter * 1.5 $gutter * 5 0 $gutter * 5;
            padding: $gutter $gutter $gutter * 2 $gutter;
            background-color: var(--c-grey-lighter);

            @media #{$media-bx} {
                margin: $gutter * 1.5 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                margin: $gutter * 1.5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                margin: $gutter $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                margin: $gutter / 2 0;
                padding: $gutter / 2;
            }

            h1 {
                font-size: 4rem;

                @media #{$media-sx} {
                    font-size: 3.6rem;
                }
            }

            h3 {
                font-size: 1.8rem;
                font-weight: $f-weight-300;
            }

            .form_gravity {
                display: flex;
                justify-content: center;
                margin-top: $gutter / 2;

                .gform_wrapper {
                    width: 60%;

                    @media #{$media-bx} {
                        width: 80%;
                    }

                    @media #{$media-m} {
                        width: 100%;
                    }
                }
            }

            .gform_confirmation_message {
                width: 60%;
                margin: $gutter;
                font-weight: $f-weight-300;
                font-size: 1.8rem;

                @media #{$media-b} {
                    width: 80%;
                }

                @media #{$media-m} {
                    width: auto;
                }

                @media #{$media-sm} {
                    margin: $gutter 0;
                }
            }
        }
    }

    // .contatti__form

    .contatti_altri {
        display: flex;

        .col-1 {
            width: 100%;

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            .contatti_repeater {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                margin: $gutter * 1.5 $gutter * 5 0 $gutter * 5;
                padding: $gutter $gutter;
                background-color: var(--c-grey-lighter);

                @media #{$media-bx} {
                    margin: $gutter * 1.5 $gutter * 2 0 $gutter * 3;
                }

                @media #{$media-m} {
                    margin: $gutter * 1.5 $gutter * 2 0 $gutter * 2;
                }

                @media #{$media-s} {
                    margin: $gutter $gutter 0 $gutter;
                }

                @media #{$media-sx} {
                    margin: $gutter / 2 0;
                    padding: $gutter / 2;
                    gap: 20px;
                }

                .item {
                    width: 28%;

                    @media #{$media-m} {
                        width: 45%;
                    }

                    @media #{$media-s} {
                        width: 100%;
                    }

                    h4 {
                        font-size: 2.2rem;
                        font-weight: $f-weight-500;
                        margin-bottom: $gutter / 4;

                        @media #{$media-sx} {
                            font-size: 1.8rem;
                        }
                    }

                    h6 {
                        font-size: 2.2rem;
                        font-weight: $f-weight-700;

                        @media #{$media-b} {
                            font-size: 2rem;
                        }

                        @media #{$media-sx} {
                            font-size: 1.8rem;
                        }

                        a {
                            font-weight: $f-weight-300;
                        }
                    }
                }
            }

            // .contatti_repeater
        }
    }

    // .contatti_altri

}

// .page-template-contatti