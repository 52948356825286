/*------------------------------------*\
    Page Template: Landing
    landing.php
    .page-template-landing
\*------------------------------------*/

.page-template-landing {
    header, footer, .otgs-development-site-front-end {
        display: none;
    }

    .header_landing {
        height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .brand {

            width: 28rem;
            height: 4rem;
            z-index: 999999;
    
            @media #{$media-m} {
                width: 20rem;
            }
    
            @media #{$media-sm} {
                display: flex;
                align-items: center;
                padding-left: $gutter / 2;
                margin-bottom: 0;
                height: 6rem;
            }
    
            .link {
                width: 100%;
                display: block;
    
                .brand_mobile {
                    display: none;
                }
    
                @media #{$media-sm} {
                    .brand_mobile {
                        display: block;
                        height: auto;
                    }
                    .brand_desktop {
                        display: none;
                    }
                }
            }
    
        }
    }
    //.header_landing

    .footer_landing {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        background-color: var(--c-grey-normal);

        a {
            color: var(--c-accent);
            margin-top: $gutter / 4;
            text-decoration: underline;
        }
    }

    .wp-block-columns {
        display: flex;

        @media #{$media-sm} {
            flex-direction: column;
        }
    }

    .wp-block-column {
        padding: $gutter / 2;

        @media #{$media-sm} {
            padding: $gutter / 2 0;
        }
    }

    #landing_body {
        padding: $gutter $gutter $gutter * 4 $gutter;

        h1 {
            font-size: 6rem;
        }

        h2 {
            font-size: 5rem;
        }

        h3 {
            font-size: 4rem;
        }

        h4 {
            font-size: 3rem;
        }

        h5 {
            font-size: 2.6rem;
        }

        h6 {
            font-size: 2.2rem;
        }

        img {
            height: auto;
        }

        p {
            font-weight: 300;
            font-size: 2.2rem;
        }
    }
}

// .page-template-landing