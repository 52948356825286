/*------------------------------------*\
    Page Template: Supporto
    supporto.php
    .page-template-supporto
\*------------------------------------*/

.page-template-supporto {

    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .supporto__header {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        position: relative;
        height: 100vh;

        @media #{$media-sx} {
            height: auto;
        }

        figure {
            position: absolute;
            pointer-events: none;
            bottom: 0;
            opacity: 0.2;
            transform: scale(1.8);
            z-index: -1;

            @media #{$media-sm} {
                width: 200vw;
            }
        }

        .col-1 {
            width: 100%;
            padding: $gutter * 5 $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 3 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter * 3 $gutter / 2 0 $gutter / 2;
            }

            h1 {
                font-size: 1.8rem;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h3 {
                font-size: 6.5rem;
                font-weight: $f-weight-500;
                margin-top: $gutter / 2;

                @media #{$media-m} {
                    font-size: 5.6rem;
                    line-height: 1.3;
                }

                @media #{$media-sm} {
                    font-size: 5rem;
                }

                @media #{$media-sx} {
                    font-size: 4rem;
                }
            }

            p {
                font-size: 3rem;
                font-weight: $f-weight-300;
                line-height: 1.4;
                max-width: 80%;
                margin-top: $gutter * 2;

                @media #{$media-bx} {
                    max-width: 100%;
                }

                @media #{$media-m} {
                    font-size: 2.6rem;
                    margin-top: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2rem;
                    margin-top: $gutter / 2;
                }
            }
        }

        //.col-1

        .pulsanti_supporto {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            gap: 40px;
            padding: 0 $gutter * 5;
            margin-top: $gutter * 2;

            @media #{$media-bx} {
                padding: 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: 0 $gutter * 2;
                gap: 20px;
            }

            @media #{$media-s} {
                padding: 0 $gutter;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
                margin-top: $gutter;
                margin-bottom: $gutter * 2;
            }

            .pulsante {
                width: 30%;
                height: $gutter * 3;
                background-color: var(--c-white);
                transition: $trans-default;

                @media #{$media-sm} {
                    width: 100%;
                    height: auto;
                    padding-bottom: $gutter / 2;
                }

                @media #{$media-sx} {
                    padding-bottom: $gutter / 4;
                }

                &:hover {
                    transform: scale(0.9);
                }

                a {
                    font-size: 2rem;
                    font-weight: $f-weight-300;
                    text-transform: uppercase;
                    text-decoration: underline;
                    padding: $gutter / 2;
                    height: 100%;
                    width: 100%;
                    display: inline-block;

                    @media #{$media-m} {
                        font-size: 1.8rem;
                    }

                    @media #{$media-sx} {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
        }

    }
    // .supporto__header

    .supporto__collegamento_remoto {
        display: flex;

        .col-1 {
            width: 100%;
            margin: 0 $gutter * 5 0 $gutter * 5;
            padding: $gutter / 2 $gutter;
            background-color: var(--c-grey-lighter);

            @media #{$media-bx} {
                margin: 0 $gutter * 3 0 $gutter * 3;
            }

            @media #{$media-m} {
                margin: 0 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                margin: 0 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                margin: 0 $gutter / 2 0 $gutter / 2;
                padding: $gutter / 2 $gutter / 2;
            }

            h1 {
                font-size: 4rem;

                @media #{$media-s} {
                    font-size: 3.6rem;
                }

                @media #{$media-sx} {
                    font-size: 3.4rem;
                    line-height: 1.3;
                    text-align: center;
                    margin-bottom: $gutter / 2;
                }
            }

            .content {
                display: flex;
                justify-content: space-between;

                @media #{$media-m} {
                    flex-direction: column;
                }

                p {
                    font-size: 1.8rem;
                    font-weight: $f-weight-300;
                }

                figure {
                    flex: 0 0 auto;
                    margin-left: $gutter * 2;

                    @media #{$media-m} {
                        margin-left: 0;
                        margin-top: $gutter / 2;
                    }

                    @media #{$media-s} {
                        justify-content: center;
                        
                        img {
                            max-width: 80%;
                            height: auto;
                        }
                    }
                }
            }

            .download {
                margin-top: $gutter;
                display: flex;
                flex-direction: column;

                .item {
                    margin-bottom: $gutter / 2;
                    transition: $trans-default;
                    transform-origin: center;

                    &:hover {
                        transform: translateX(10px);

                        figure {
                            transform: scale(1.2);
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        gap: 20px;

                        .text {
                            @media #{$media-sx} {
                                flex: 1 0 auto;
                            }
                        }

                        .mobile { 
                            display: none; 

                            @media #{$media-sx} {
                                display: block;
                            }
                        }

                        .desktop { 
                            display: block; 

                            @media #{$media-sx} {
                                display: none;
                            }
                        }

                        figure {
                            transition: $trans-default;
                        }

                        h3 {
                            font-size: 1.6rem;
                            font-weight: $f-weight-500;
                            line-height: 1;

                            @media #{$media-sx} {
                                font-size: 1.4rem;
                            }
                        }

                        span {
                            font-size: 1.4rem;
                            font-weight: $f-weight-300;
                        }
                    }
                }
            }
        }

        //.col-1
    }
    //.supporto_collegamento_remoto

    .supporto__collegamento_come {
        display: flex;

        .col-1 {
            width: 100%;
            margin: $gutter * 3 $gutter * 5 0 $gutter * 5;
            padding: $gutter / 2 $gutter $gutter;
            background-color: var(--c-grey-lighter);

            @media #{$media-bx} {
                margin: $gutter * 3 $gutter * 3 0 $gutter * 3;
            }

            @media #{$media-m} {
                margin: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                margin: $gutter * 2 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                margin: $gutter * 2 $gutter / 2 0 $gutter / 2;
                padding: $gutter / 2 0;
            }

            h1 {
                font-size: 4rem;

                @media #{$media-s} {
                    font-size: 3.6rem;
                }

                @media #{$media-sx} {
                    font-size: 3.4rem;
                    line-height: 1.3;
                    text-align: center;
                    margin-bottom: $gutter / 2;
                }
            }

            p {
                font-size: 1.8rem;
                font-weight: $f-weight-300;
                max-width: 80%;

                @media #{$media-m} {
                    max-width: none;
                }

                @media #{$media-sx} {
                    padding: 0 $gutter / 2;
                }
            }

            .content {
                margin-top: $gutter;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 20px;

                details {
                    flex: 1 0 auto;
                    background-color: var(--c-white);
                    padding: $gutter / 4 $gutter / 2;
                    max-width: 32%;
                    
                    @media #{$media-bx} {
                        max-width: 48%;
                    }

                    @media #{$media-sm} {
                        max-width: 100%;
                        padding: $gutter / 2;
                    }

                    summary {
                        h3 {
                            font-size: 2.2rem;
                            font-weight: $f-weight-300;
                            min-height: $gutter * 2.5;

                            @media #{$media-sx} {
                                min-height: 0;
                                font-size: 1.8rem;
                                font-weight: $f-weight-400;
                            }
                        }

                        p {
                            max-width: 100%;
                            padding: 0;
                        }

                        .first {
                            min-height: $gutter * 4.5;

                            @media #{$media-sx} {
                                min-height: 0;
                            }
                        }

                        .last {
                            p {
                                margin-bottom: $gutter / 2;

                                @media #{$media-sx} {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        //.col-1
    }
    //.supporto_collegamento_come
}

// .page-template-supporto