/*------------------------------------*\
    Page Template: Chisiamo
    chisiamo.php
    .page-template-chisiamo
\*------------------------------------*/

.page-template-chisiamo {

    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .chisiamo__header {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        height: 100vh;

        .scrittaBg {
            position: absolute;
            pointer-events: none;
            left: 0;
            bottom: 0;
            z-index: -1;
            opacity: 0.2;

            @media #{$media-sm} {
                width: 200vw;
            }

            @media #{$media-sx} {
                width: 300vw;
                bottom: - $gutter * 3;
            }
        }

        .col-1 {
            width: 100%;
            padding: $gutter * 5 $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 3 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter * 3 $gutter / 2 0 $gutter / 2;
            }

            h1 {
                font-size: 1.8rem;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h3 {
                font-size: 8rem;
                font-weight: $f-weight-500;
                text-transform: uppercase;
                color: var(--c-corporate-3);
                text-align: center;
                margin-top: $gutter * 3;
                line-height: 1.3;

                @media #{$media-m} {
                    font-size: 5.6rem;
                    line-height: 1.3;
                }

                @media #{$media-sm} {
                    font-size: 5rem;
                }

                @media #{$media-sx} {
                    font-size: 4rem;
                    margin-top: $gutter;
                }
            }

            p {
                font-size: 2.2rem;
                font-weight: $f-weight-300;
                margin-top: $gutter;
                text-align: center;

                @media #{$media-m} {
                    margin-top: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2rem;
                }
            }
        }

        //.col-1

    }

    // .chisiamo__header

    .chisiamo__section1 {
        display: flex;
        flex-wrap: wrap;
        background-color: var(--c-corporate-2);
        color: var(--c-grey-light);
        align-items: center;

        .col-1 {
            width: 50%;
            overflow: hidden;

            @media #{$media-b} {
                width: 40%;
            }

            @media #{$media-m} {
                width: 100%;
                margin-top: - $gutter * 4;
                z-index: 1;
            }

            figure {
                margin-left: - $gutter * 5;

                @media #{$media-b} {
                    margin-left: - $gutter * 10;
                }

                @media #{$media-m} {
                    margin-left: - $gutter * 5;
                }

                @media #{$media-sm} {
                    max-height: 400px;
                    margin-bottom: $gutter;
                }

                img {
                    height: auto;
                    max-width: 55%;

                    @media #{$media-bx} {
                        max-width: 75%;
                    }

                    @media #{$media-m} {
                        max-width: 40%;
                    }

                    @media #{$media-sm} {
                        max-width: none;
                    }
                }
            }
        }

        .col-2 {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding: $gutter * 1.5 0;

            @media #{$media-b} {
                width: 60%;
            }

            @media #{$media-m} {
                width: 100%;
                order: -1;
                align-items: center;
                padding: $gutter * 2 0 0 0;
                z-index: 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 2 $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter $gutter;
            }

            p {
                max-width: 600px;
                font-size: 2.2rem;
                font-weight: $f-weight-300;
                margin-bottom: $gutter;
                line-height: 1.8;

                @media #{$media-sm} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    text-align: center;
                    line-height: 1.6;
                }
            }
        }
    }

    // .chisiamo__section1

    .chisiamo__section2 {
        display: flex;

        .col-1 {
            width: 100%;
            padding-top: $gutter;

            article {
                padding: $gutter * 2 $gutter * 5 0 $gutter * 5;

                @media #{$media-bx} {
                    padding: $gutter * 2 $gutter * 2 0 $gutter * 3;
                }

                @media #{$media-m} {
                    padding: $gutter * 2 $gutter * 2 0 $gutter * 2;
                }

                @media #{$media-sm} {
                    padding: $gutter * 2 $gutter * 2 0 $gutter * 2;
                }

                @media #{$media-s} {
                    padding: $gutter * 2 $gutter 0 $gutter;
                }

                @media #{$media-sx} {
                    padding: $gutter * 2 $gutter / 2 0 $gutter / 2;
                    text-align: center;
                }

                h2 {
                    font-size: 1.8rem;

                    @media #{$media-sx} {
                        font-size: 1.6rem;
                    }
                }

                h3 {
                    font-size: 6rem;
                    line-height: 1.3;
                    margin: $gutter / 4 0;

                    @media #{$media-m} {
                        font-size: 5.6rem;
                        line-height: 1.3;
                    }

                    @media #{$media-sm} {
                        font-size: 5rem;
                    }

                    @media #{$media-sx} {
                        font-size: 4rem;
                    }
                }

                p {
                    font-size: 2.2rem;
                    font-weight: $f-weight-300;
                    margin-bottom: $gutter;
                    max-width: 50vw;

                    @media #{$media-b} {
                        max-width: none;
                    }

                    @media #{$media-sm} {
                        font-size: 2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }

                .voci_elenco {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 40px;
                    margin: $gutter 0 $gutter * 1.5;
                    text-align: left;
    
                    @media #{$media-m} {
                        margin: $gutter 0 0;
                    }
    
                    @media #{$media-sm} {
                        gap: 20px;
                    }
    
                    @media #{$media-sx} {
                        margin-bottom: $gutter;
                    }
    
                    h4 {
                        width: 30%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--c-white);
                        font-size: 2.2rem;
                        font-weight: $f-weight-300;
                        padding: $gutter / 3;
    
                        @media #{$media-m} {
                           width: 47%; 
                        }
    
                        @media #{$media-sm} {
                            width: 100%;
                            font-size: 2rem;
                        }
    
                        @media #{$media-sx} {
                            font-size: 1.8rem;
                        }
                    }
                }
            }


        }
    }

    // .chisiamo__section2

    .chisiamo__timeline {
        .timeline {
            position: relative;
            padding: $gutter * 1.5 0 0 $gutter * 5.5;

            @media #{$media-b} {
                padding: 0 0 0 $gutter * 3.5;
            }

            @media #{$media-sm} {
                padding: 0 0 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: 0 0 0 $gutter / 2;
            }

            @media #{$media-sx} {
                padding: 0;
            }

            &::before {
                position: absolute;
                top: 13rem;
                left: 24.5rem;
                z-index: -1;
                content: '';
                width: 1px;
                height: 89%;
                border: 1px solid var(--c-corporate-1);

                @media #{$media-b} {
                    top: 6rem;
                    left: 16.5rem;
                    height: 92%;
                }

                @media #{$media-sm} {
                    left: 10.5rem;
                }

                @media #{$media-s} {
                    left: 6.5rem;
                }

                @media #{$media-sx} {
                    left: 4.5rem;
                }
            }

            .forma {
                &.forma--uno {
                    right: -38rem;
                    top: -30rem;
                }
            }

            .content {
                padding: $gutter / 3 $gutter / 2;
                position: relative;

                @media #{$media-sx} {
                    padding-left: 0;
                }

                h3 {
                    font-weight: 700;
                }

                p {
                    font-size: 1.8rem;
                    font-weight: 300;

                    @media #{$media-m} {
                        font-size: 1.6rem;
                    }
                }
            }

            .box-timeline {
                display: flex;
                position: relative;
                align-items: center;
                min-height: $gutter * 3;

                @media #{$media-s} {
                    padding-left: $gutter * 1.5;

                    @media #{$media-s} {
                        padding-left: $gutter / 2;
                    }
                }

                .year {
                    font-size: 2.8rem;
                    padding: $gutter / 8 0 0 $gutter / 1.5;
                    width: $gutter * 2.5;
                    flex: 0 0 auto;

                    @media #{$media-sx} {
                        font-size: 2.4rem;
                    }
                }

                .content {
                    font-size: 2.2rem;
                    font-weight: $f-weight-300;
                }

                svg {
                    transition: $trans-default;
                    flex: 0 0 auto;

                    path {
                        transition: $trans-default;
                    }
                }

                &.aos-animate {
                    svg {
                        animation-name: svg_timeline_scale;
                        animation-duration: 1s;
                        transform-origin: center;

                        path {
                            animation-name: svg_timeline_fill;
                            animation-duration: 0.5s;
                        }
                    }
                }

                @keyframes svg_timeline_scale {
                    0% {
                        transform: scale(1)
                    }

                    10% {
                        transform: scale(1.8)
                    }

                    60% {
                        transform: scale(1.8)
                    }

                    100% {
                        transform: scale(1)
                    }
                }

                @media #{$media-sx} {
                    @keyframes svg_timeline_scale {
                        0% {
                            transform: scale(1)
                        }

                        10% {
                            transform: scale(1.4)
                        }

                        60% {
                            transform: scale(1.4)
                        }

                        100% {
                            transform: scale(1)
                        }
                    }
                }

                @keyframes svg_timeline_fill {
                    0% {
                        fill: var(--c-corporate-3)
                    }

                    60% {
                        fill: var(--c-corporate-3)
                    }

                    100% {
                        fill: var(--c-white)
                    }
                }
            }
        }
    }

    //.chisiamo__timeline
}

// .page-template-chisiamo