/*------------------------------------*\
    Page Generico e legal: Template base
    page-content.php legal-content.php
    .page-template-default .page-template-legal
\*------------------------------------*/

.page-template-default,
.page-template-legal {

    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .post--generico {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .col-1 {
            width: 100%;
            padding: $gutter * 5 $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 3 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter * 3 $gutter / 2 $gutter / 2 $gutter / 2;
            }
        }

        h1 {
            font-size: 1.8rem;

            @media #{$media-sx} {
                font-size: 1.6rem;
            }
        }

        h3 {
            font-size: 6.5rem;
            font-weight: $f-weight-500;
            margin-top: $gutter / 2;

            @media #{$media-m} {
                font-size: 5.6rem;
                line-height: 1.3;
            }

            @media #{$media-sm} {
                font-size: 5rem;
            }

            @media #{$media-sx} {
                font-size: 4rem;
            }
        }

        .text {
            margin-top: $gutter;
            font-size: 1.8rem;

            @media #{$media-sx} {
                font-size: 1.6rem;
            }
        }

        #iubenda_policy.iubenda_fluid_policy .iub_container {
            margin-top: 0;
        }

        #iubenda_policy .iub_content {
            padding: 0;
        }

    }

    //.post--generico
}

//.page-template-default 