// Lato Regular
@font-face {
    font-family:"lato";
    src:url("https://use.typekit.net/af/180254/00000000000000000001522c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/180254/00000000000000000001522c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/180254/00000000000000000001522c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:swap;
    font-style:normal;
    font-weight:400;
    font-stretch:normal;
}

// Josefin
@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/JosefinSans-Bold.woff2') format('woff2'),
        url('../fonts/JosefinSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    font-stretch:normal;
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/JosefinSans-ExtraLight.woff2') format('woff2'),
        url('../fonts/JosefinSans-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    font-stretch:normal;
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/JosefinSans-Medium.woff2') format('woff2'),
        url('../fonts/JosefinSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    font-stretch:normal;
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/JosefinSans-Regular.woff2') format('woff2'),
        url('../fonts/JosefinSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-stretch:normal;
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/JosefinSans-Light.woff2') format('woff2'),
        url('../fonts/JosefinSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    font-stretch:normal;
}

