/*------------------------------------*\
    Page Template: Partners
    partners.php
    .page-template-partners
\*------------------------------------*/

.page-template-partners {

    .partners__header {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        figure {
            position: absolute;
            pointer-events: none;
            right: 0;
            transform: scaleY(1.5);
        }

        .col-1 {
            width: 100%;
            padding: $gutter * 5 $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 3 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter * 3 $gutter / 2 0 $gutter / 2;
            }

            h1 {
                font-size: 1.8rem;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h3 {
                font-size: 6.5rem;
                font-weight: $f-weight-500;
                margin-top: $gutter / 2;

                @media #{$media-m} {
                    font-size: 5.6rem;
                    line-height: 1.3;
                }

                @media #{$media-sm} {
                    font-size: 5rem;
                }

                @media #{$media-sx} {
                    font-size: 4rem;
                }
            }

            p {
                font-size: 3rem;
                font-weight: $f-weight-300;
                line-height: 1.4;
                max-width: 80%;
                margin-top: $gutter * 2;

                @media #{$media-bx} {
                    max-width: 100%;
                }

                @media #{$media-m} {
                    font-size: 2.6rem;
                    margin-top: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2rem;
                    margin-top: $gutter / 2;
                }
            }
        }

        //.col-1

    }
    // .partners__header

    .elenco__partners {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 40px;
        padding: $gutter * 5 $gutter * 5 0 $gutter * 5;

        @media #{$media-bx} {
            padding: $gutter * 5 $gutter * 2 0 $gutter * 3;
        }

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2 0 $gutter * 2;
        }

        @media #{$media-s} {
            padding: $gutter * 2 $gutter 0 $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2 0 $gutter / 2;
            gap: 20px;
        }
        
        article {
            width: 38%;
            min-height: 210px;
            background-color: var(--c-grey-normal);
            position: relative;
            padding: $gutter / 2 $gutter / 2 $gutter / 2 0;
            margin-bottom: $gutter * 3.5;
            display: flex;

            @media #{$media-bx} {
                width: 42%;
                max-width: 500px;
            }

            @media #{$media-m} {
                width: 100%;
                padding-top: 0;
                padding-bottom: 0;
                min-height: 0;
                max-width: none;
                align-items: center;
                margin-bottom: $gutter / 2;
            }

            figure {
                width: 250px;
                height: 226px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                background-color: var(--c-white);
                border: 1px solid var(--c-grey-dark);
                padding: $gutter / 2;
                margin-left: - $gutter * 2;
                margin-top: - $gutter * 3;

                @media #{$media-b} {
                    width: 200px;
                    height: 180px;
                }

                @media #{$media-m} {
                    margin: 0;
                }

                @media #{$media-sx} {
                    width: 140px;
                    height: 140px;
                }

                img {
                    width: auto;
                    max-height: 100%;
                    height: auto;
                }
            }

            h4 {
                color: var(--c-theme-2);
                font-size: 4rem;
                padding-left: $gutter / 2;
                
                @media #{$media-s} {
                    font-size: 3rem;
                }

                @media #{$media-sx} {
                    font-size: 2.4rem;
                }
            }

            p {
                font-size: 1.8rem;
                font-weight: $f-weight-300;
                padding-left: $gutter / 2;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }
    }
    // .elenco__partners
}

// .page-template-partners