/*------------------------------------*\
    Footer
   #footer.footer
\*------------------------------------*/

.footer {

    margin-top: $gutter * 4;
    font-family: $f-family-title;

    position: relative;
    z-index: 1;

    @media #{$media-m} { margin-top: $gutter*2; }
    @media #{$media-sx} { margin-top: $gutter; }

    section {
        @media #{$media-uw} {
            width: $w-media-uw;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .footer__location {
        display: flex;
        flex-wrap: wrap;
        padding: $gutter * 2 $gutter 0 $gutter;
        overflow-y: hidden;

        @media #{$media-sm} {
            padding: $gutter / 2 $gutter / 2;
        }

        @media #{$media-s} {
            padding: $gutter / 4 $gutter / 2;
        }

        .logo {
            width: 15%;
            border-right: 1px solid var(--c-corporate-3);
            padding-right: $gutter;

            @media #{$media-bx} {
                width: 20%;
            }

            @media #{$media-b} {
                width: 25%;
            }

            @media #{$media-m} {
                width: 40%;
            }

            @media #{$media-s} {
                width: 100%;
                border-right: 0;
                border-bottom: 1px solid var(--c-corporate-3);
                padding-right: 0;
                padding-bottom: $gutter / 2;
                margin-bottom: $gutter / 2;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .brand_footer {
                width: 21rem;

                svg {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }

            .text {
                font-size: 1.2rem;
                font-weight: 300;
                margin-top: $gutter;
            }
            
        }//.logo

        .location {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            @media #{$media-sm} {
                gap: 2rem;
                justify-content: center;
            }
    
            @media #{$media-s} {
                gap: 0;
            }

            .left {
                a {
                    width: 200px;
                    height: auto;
                    display: flex;
                    margin-bottom: $gutter / 2;

                    @media #{$media-s} {
                        width: 180px;
                        height: auto;
                    }

                    img {
                        max-width: 106px;
                        height: auto;
                        margin-left: 0.5rem;
                    }
                }
            }

            .middle {
                display: flex;
                flex-direction: column;
                align-items: center;

                @media #{$media-sx} {
                    padding-bottom: $gutter;
                    padding-top: $gutter / 2;
                    width: 100%;
                    text-align: center;
                }

                .item {
                    display: flex;
                    justify-content: center;
                }

                .text {
                    font-size: 2.2rem;
                    font-weight: $f-weight-300;

                    a {
                        font-size: 2rem;
                        font-weight: $f-weight-500;
                        margin-left: $gutter / 4;
                    }
                }

                .link {
                    width: 40px;
                    height: 40px;
                    margin-top: 10px;
                } 
            }

            .right {
                display: flex;
                height: 100%;
                position: relative;

                @media #{$media-b} {
                    margin-bottom: - $gutter / 2;
                }

                @media #{$media-m} {
                    justify-content: center;
                    height: auto;
                }

                @media #{$media-sx} {
                    margin-bottom: $gutter;
                }

                .link-box{
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    @media #{$media-sm} {
                        margin: 20px 0;
                    }

                    @media #{$media-sx} {
                        margin: 0;
                    }

                    svg{
                        width: auto;
                        min-height: 75px;
                    }
                } 

                .grafica {
                    display: none;
                    align-self: flex-end;
                    margin-bottom: - $gutter * 4.5;

                    @media #{$media-sx} {
                        display: none;
                    }
                }
            }
        }//.location

    }//.footer__location


    .footer__policies {
        display: flex;
        flex-wrap: wrap;
        background-color: var(--c-grey-normal);
        z-index: 2;
        position: relative;

        section {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            padding: $gutter * 1.2 $gutter;

            @media #{$media-sx} {
                padding: $gutter / 2;
            }
            
            .copy, .items {

                @media #{$media-m} {
                    width: 100%;
                    font-size: 1.4rem;
                }
            }

            .copy {
                width: 45%;
                font-size: 1.8rem;
                font-weight: $f-weight-300;

                @media #{$media-bx} {
                    width: 40%;
                }

                @media #{$media-s} {
                    width: 50%;
                }

                @media #{$media-sx} {
                    width: 100%;
                    font-size: 1.4rem;
                }
            }

            .items {
                width: 55%;
                display: flex;
                justify-content: flex-end;

                @media #{$media-bx} {
                    width: 60%;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    gap: 10px;
                }

                @media #{$media-b} {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    gap: $gutter / 4;
                }

                @media #{$media-m} {
                    order: -1;
                    margin-bottom: $gutter / 2;
                }

                @media #{$media-s} {
                    width: 50%;
                }

                @media #{$media-sx} {
                    flex-direction: column;
                    align-items: center;
                    gap: 0;
                    width: 100%;
                }  

                .item {
                    padding: 0 $gutter / 2;
                    font-size: 1.8rem;

                    @media #{$media-sx} {
                        padding: $gutter / 4;
                        font-size: 1.6rem;
                    }
                }
            }

            .item:last-child {
                border-right: 0;
            }
        }

    }//.footer__policies


}// .footer
