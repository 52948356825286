/*------------------------------------*\
    Page Index: Blog
    index.php
    .archive
\*------------------------------------*/

.archive,
.single-post {

    .post--archive,
    .post--single {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .col-1 {
            width: 100%;
            padding: $gutter * 5 $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter * 5 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter * 3 $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter * 3 $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                text-align: center;
                padding: $gutter * 3 $gutter / 2 0 $gutter / 2;
            }

            h1 {
                font-size: 6.5rem;
                font-weight: $f-weight-500;
                margin-top: $gutter;
                line-height: 1.1;

                @media #{$media-m} {
                    font-size: 5.6rem;
                    line-height: 1.3;
                }

                @media #{$media-sm} {
                    font-size: 5rem;
                }

                @media #{$media-sx} {
                    font-size: 4rem;
                    margin-top: $gutter / 2;
                }
            }

            p,
            h2 {
                font-size: 1.8rem;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-1

        .blog--list {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: $gutter $gutter * 5 0 $gutter * 5;

            @media #{$media-bx} {
                padding: $gutter $gutter * 2 0 $gutter * 3;
            }

            @media #{$media-m} {
                padding: $gutter $gutter * 2 0 $gutter * 2;
            }

            @media #{$media-s} {
                padding: $gutter $gutter 0 $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter $gutter / 2 0 $gutter / 2;
            }

            .container_filters {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-end;
                margin-top: $gutter * 1.5;

                .filterByYear {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-size: 1.8rem;

                    ul {
                        display: flex;
                        gap: 10px;

                        a {
                            text-decoration: underline;

                            &[aria-current="page"] {
                                color: var(--c-accent);
                            }
                        }
                    }
                }

                .pagination {
                    margin-top: 0;
                }
            }

            .box_news {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                gap: 40px;

                @media #{$media-bx} {
                    gap: 30px;
                }

                @media #{$media-b} {
                    gap: 20px;
                }

                @media #{$media-sx} {
                    padding: 0 $gutter / 2;
                }

                .single_news {
                    width: 30%;
                    max-width: 480px;
                    border: 2px solid var(--c-grey-normal);

                    @media #{$media-bx} {
                        width: 32%;
                    }

                    @media #{$media-b} {
                        width: 48%;
                    }

                    @media #{$media-sm} {
                        width: 100%;
                        max-width: none;
                    }

                    .top {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: $gutter * 1.5;
                        border-bottom: 2px solid var(--c-grey-normal);
                        position: relative;
                        font-size: 1.8rem;
                        font-weight: $f-weight-200;

                        img {
                            position: absolute;
                            right: $gutter / 3;
                            width: 35px;
                            height: auto;
                        }
                    }

                    .title {
                        display: flex;
                        justify-content: center;
                        padding: $gutter / 2 $gutter / 2;
                        font-size: 2.4rem;
                        line-height: 1.3;
                        text-align: center;
                        min-height: $gutter * 2.5;
                        overflow: hidden;
                        border-bottom: 2px solid var(--c-grey-normal);

                        @media #{$media-m} {
                            min-height: $gutter * 3;
                        }

                        @media #{$media-sx} {
                            font-size: 2rem;
                            min-height: 0;
                        }
                    }

                    .content {
                        padding: $gutter / 2;

                        .text {
                            display: flex;

                            figure {
                                flex: 0 0 auto;
                                width: 120px;
                                margin-right: $gutter / 2;

                                @media #{$media-b} {
                                    width: 80px;
                                }

                                @media #{$media-sm} {
                                    width: 20vw;
                                }

                                @media #{$media-sx} {
                                    width: 14vw;
                                }
                            }

                            p {
                                font-size: 1.6rem;
                                font-weight: $f-weight-300;
                                min-height: $gutter * 3;

                                @media #{$media-sx} {
                                    min-height: 0;
                                }
                            }
                        }

                        .link {
                            display: flex;
                            justify-content: center;

                            a {
                                display: inline-flex;
                                align-items: center;
                                margin-top: $gutter / 2;

                                .text {
                                    font-size: 1.8rem;
                                    font-weight: $f-weight-300;
                                    margin-left: $gutter / 3;
                                    text-transform: uppercase;
                                    transition: $trans-default;
                                }

                                svg {
                                    transition: $trans-default;
                                }

                                &:hover {
                                    color: var(--c-accent);

                                    .text {
                                        transform: translateX(10px);
                                    }

                                    svg {
                                        transform: scale(1.1) translateX(10px);

                                        g,
                                        path {
                                            stroke: var(--c-accent);
                                            stroke-width: 1px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        //.blog--list

        .blog--detail {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: $gutter;

            .col-1 {
                width: 55%;
                padding: 0 $gutter * 2 0 $gutter * 5;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media #{$media-bx} {
                    padding: 0 $gutter * 2 0 $gutter * 3;
                }

                @media #{$media-m} {
                    width: 100%;
                    padding: 0 $gutter * 2 0 $gutter * 2;
                }

                @media #{$media-s} {
                    padding: 0 $gutter;
                }

                @media #{$media-sx} {
                    padding: 0 $gutter / 2;
                }

                .time {
                    font-weight: $f-weight-300;
                    padding-bottom: $gutter / 2;
                    display: flex;
                }

                .text {
                    font-weight: $f-weight-300;

                    @media #{$media-sx} {
                        font-size: 1.5rem;
                    }
                }

                .body {
                    flex-direction: column;
                    padding-bottom: $gutter * 2;
                    font-weight: $f-weight-300;
                    text-align: left;

                    @media #{$media-sx} {
                        padding-bottom: $gutter;
                    }
                }

                .allegati {
                    display: flex;
                    flex-direction: column;

                    .item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1 0 auto;
                        border-bottom: 1px solid var(--c-grey-dark);
                        transition: $trans-default;

                        @media #{$media-sx} {
                            font-size: 1.4rem;
                        }

                        &:hover {
                            border-bottom: 1px solid var(--c-accent);
                            color: var(--c-accent)
                        }

                        a {
                            padding: $gutter / 4 0;
                            display: flex;
                            text-align: left;
                        }

                        img {
                            @media #{$media-sx} {
                                transform: scale(0.6);
                            }
                        }

                        .attachment {
                            flex: 0 0 auto;

                            a {
                                font-size: 1.2rem;
                                display: flex;
                                align-items: center;

                                span {
                                    margin-left: $gutter / 2;

                                    @media #{$media-sx} {
                                       margin-left: 0;
                                       font-size: 1.1rem;
                                    }

                                    strong {
                                        font-weight: $f-weight-700;
                                    }
                                }
                            }
                        }
                    }

                    .item:last-child {
                        border-bottom: 0;
                    }
                }

                .link {
                    display: flex;

                    a {
                        display: inline-flex;
                        align-items: center;
                        margin-top: $gutter / 2;

                        .text {
                            font-size: 1.8rem;
                            font-weight: $f-weight-300;
                            margin-left: $gutter / 3;
                            text-transform: uppercase;
                            transition: $trans-default;
                        }

                        svg {
                            transform: rotate(180deg);
                            transition: $trans-default;
                        }

                        &:hover {
                            color: var(--c-accent);

                            .text {
                                transform: translateX(-10px);
                            }

                            svg {
                                transform: scale(1.1) translateX(-10px) rotate(180deg);

                                g,
                                path {
                                    stroke: var(--c-accent);
                                    stroke-width: 1px;
                                }
                            }
                        }
                    }
                }
            }

            .col-2 {
                width: 45%;
                position: relative;

                @media #{$media-m} {
                    width: 100%;
                    padding: 0 $gutter * 2 0 $gutter * 2;
                    margin-bottom: $gutter;
                    order: -1;
                }

                @media #{$media-s} {
                    padding: 0 $gutter 0 $gutter;
                }

                @media #{$media-sx} {
                    padding: 0 $gutter / 2;
                }

                .time {
                    color: var(--c-corporate-2);
                    display: inline-block;
                    margin-bottom: $gutter / 3;
                }

                
                .content-gallery {
                    width: 600px;
                    max-height: 600px;

                    @media #{$media-s} {
                        width: 100%;
                        max-height: 400px;
                    }

                    @media #{$media-sx} {
                        max-height: 320px;
                    }

                    .gallery--single {

                        .item {
                            overflow: hidden;

                            @media #{$media-s} {
                                height: 400px;
                            }

                            @media #{$media-sx} {
                                height: 320px;
                            }

                            figure {
                                width: 600px;
                                height: 600px;

                                display: flex;
                                align-items: stretch;

                                @media #{$media-s} {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

            }

        }

        //.blog--detail

    }

    //.post--archive
}

//.archive